<template>
  <v-app id="inspire">
    <v-navigation-drawer
      ref="drawer"
      width="290"
      dark
      color="#1D1D1B"
      :expand-on-hover="drawerMiniVariant && !$vuetify.breakpoint.smAndDown"
      v-model="drawer"
      app
      :clipped="isClipped"
    >
      <div class="drawer-image mx-4 mt-6 d-flex">
        <v-app-bar-nav-icon
          style="margin-left: -10px"
          @click.stop="toggleMiniDrawer(!drawerMiniVariant ? 'mini' : 'full')"
        ></v-app-bar-nav-icon>
        <a href="/" class="mediadesc-logo"></a>
      </div>

      <v-list list>
        <v-list-item
          v-for="(route, i) in routes"
          :disabled="route.disabled"
          :key="i"
          :to="{ name: route.path }"
        >
          <v-list-item-icon>
            <v-icon color="primary">{{ `mdi-${route.icon}` }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="route.text"></v-list-item-title>
        </v-list-item>
        <v-divider class="my-2"></v-divider>
        <v-list-item :disabled="cabinet.disabled" :to="{ name: cabinet.path }">
          <v-list-item-icon>
            <v-icon color="primary">{{ `mdi-${cabinet.icon}` }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="cabinet.text"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="bg-gray">
      <v-progress-circular
        indeterminate
        v-if="isLoading"
        :width="15"
        :size="100"
        class="main-preloader"
        color="primary"
      ></v-progress-circular>
      <router-view v-else class=""></router-view>

      <bug-report></bug-report>
    </v-main>
    <v-footer padless v-if="process === 'development'">
      <v-col class="d-flex align-center justify-end pa-2 " cols="12">
        <small class="ml-2"
          >DEV: Система бронирования v5.1.5K1 ({{
            new Date().toLocaleDateString()
          }})</small
        >
      </v-col>
    </v-footer>
  </v-app>
</template>
<script>
import userInfo from "@/components/common/UserInfo";
import { mapActions, mapMutations, mapState } from "vuex";
import { AUTH_ABOUT_ME } from "../../store/const/auth";
import requestControl from "@/mixins/requestControl";
import dbg from "@/plugins/dbg";
import BugReport from "../common/BugReport";

export default {
  components: {
    BugReport,
    userInfo
  },
  mixins: [requestControl],
  data() {
    return {
      isClipped: true,
      process: process.env.NODE_ENV,
      drawer: null,
      routes: [
        {
          text: "Брони",
          path: "reservation",
          disabled: false,
          icon: "ticket-confirmation",
          children: [
            {
              text: "Новая бронь",
              path: "reservation/new/:id",
              disabled: false,
              icon: "bag-checked"
            }
          ]
        },
        {
          text: "Пользователи",
          path: "control",
          disabled: false,
          icon: "account-multiple"
        }
      ],
      cabinet: {
        text: "Настройки",
        path: "cabinet",
        disabled: false,
        icon: "cog"
      }
    };
  },
  computed: {
    ...mapState("Auth", ["drawerMiniVariant"]),
    showLogo() {
      return !this.drawerMiniVariant || this.$refs?.drawer?.isMouseover;
    },
    isLoading() {
      return this.loading(AUTH_ABOUT_ME) === "loading";
    }
  },
  methods: {
    ...mapMutations("Auth", ["toggleMiniDrawer"]),
    ...mapActions("Auth", {
      getMe: AUTH_ABOUT_ME
    }),

    clearLocalStorage() {
      localStorage.clear();
    }
  },
  async created() {
    dbg(this.$route);
    //me
    if (localStorage.getItem("drawerStyle") === "mini") {
      this.$store.commit("Auth/toggleMiniDrawer", "mini");
    } else {
      this.$store.commit("Auth/toggleMiniDrawer", "full");
    }
    try {
      await this.getMe();
      this.checkRequestOnError(AUTH_ABOUT_ME);
    } catch (error) {
      dbg("GET ME INFO err", error);
    }
  }
};
</script>

<style lang="scss">
.mediadesc-logo {
  background-image: url(../../assets/white-logo.svg);
  background-position: center;
  width: 100%;
  height: 42px;
}
.main-preloader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
