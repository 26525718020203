<template>
  <div class="text-center">
    <v-dialog
      overlay-color="#040b3e"
      overlay-opacity="0.26"
      v-model="dialog"
      width="700"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="bug-report__button"
          color="primary"
          dark
          fab
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-android-messages</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Сообщить об ошибке приложения
        </v-card-title>
        <v-form ref="form" @submit.prevent="sendBugReport">
          <v-card-text style="max-height: 620px; overflow: auto">
            <v-text-field
              v-model.trim="form.error_name"
              dense
              label="Заголовок *"
              outlined
            ></v-text-field>
            <v-text-field
              v-model.trim="form.user_name"
              dense
              label="Ваши фамилия, имя"
              type="name"
              outlined
            ></v-text-field>
            <v-text-field
              v-model.trim="form.email"
              dense
              :rules="rule.email"
              label="Ваш E-mail"
              type="email"
              outlined
            ></v-text-field>
            <v-textarea
              v-model.trim="form.description"
              dense
              label="Описание ошибки"
              type="text"
              outlined
              auto-grow
            ></v-textarea>
            <v-textarea
              v-model.trim="form.error_steps"
              dense
              label="Этапы воспроизведения ошибки"
              type="text"
              outlined
            ></v-textarea>
            <v-file-input
              v-model="form.file"
              dense
              label="Добавить скриншот ошибки"
              outlined
            ></v-file-input>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded text @click="dialog = false">
              Отмена
            </v-btn>
            <v-btn @click="sendBugReport" color="primary" rounded>
              Отправить
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { SEND_BUG_REPORT } from "../../store/const/helpers";
import requestControl from "../../mixins/requestControl";
import validationRules from "../../mixins/validationRules";

export default {
  name: "BugReport",
  mixins: [requestControl, validationRules],
  data() {
    return {
      dialog: false,
      form: {
        error_name: "",
        user_name: "",
        email: "",
        description: "",
        error_steps: "",
        file: null
      }
    };
  },
  methods: {
    ...mapActions("Helpers", {
      sendReport: SEND_BUG_REPORT
    }),
    async sendBugReport() {
      try {
        const isValid = this.$refs.form.validate();
        if (isValid) {
          await this.sendReport(this.form);
          if (this.checkRequestOnError(SEND_BUG_REPORT)) {
            this.$notify({
              type: "succ",
              text: `Баг репорт успешно отправлен`
            });
            this.dialog = false;
          }
        } else {
          return;
        }
      } catch (_) {
        _;
      }
    }
  }
};
</script>

<style scoped>
.bug-report__button {
  z-index: 20;
  position: fixed;
  bottom: 15px;
  right: 15px;
}
</style>
